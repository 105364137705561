import * as React from 'react';
import TermsLayout from '../../components/termspage/TermsLayout';
import { TermsError, TermsOutline } from '../../components';
import { termsOfUseLinks } from '../../utilities/data';
import { purifyHTML } from '../../utilities/purify-html';
import { useStrapiStore } from '../../store/useStrapiStore';
import TermsLoadingPage from '../../components/termspage/TermsLoadingPage';
import { removeSpacesFromString } from '../../utilities';

const TermsPolicies = () => {
  const { termsData, termsState } = useStrapiStore((state) => ({
    termsState: state.termsState,
    termsData: state.termsData,
  }));

  if (termsState === 'loading') {
    return <TermsLoadingPage links={termsOfUseLinks} />;
  }

  if (termsData === 'error') {
    return <TermsError />;
  }

  const irorunTerms = termsData['irorun-terms'];

  return (
    <TermsLayout>
      <div>
        <div className="content">
          <TermsOutline links={termsOfUseLinks} />
          <div
            dangerouslySetInnerHTML={{
              __html: purifyHTML(
                removeSpacesFromString(irorunTerms.page_content)
              ),
            }}
          />
        </div>
      </div>
    </TermsLayout>
  );
};

export default TermsPolicies;
